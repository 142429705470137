import React, { useState, useEffect } from 'react';
import ConversationItem from './components/ConversationItem';
import ConversationPage from './pages/ConversationPage';

const App = () => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null);

    useEffect(() => {
        async function fetchConversations() {
            try {
                const response = await fetch('/api/conversations'); // Replace with your API endpoint
                if (response.ok) {
                    const data = await response.json();
                    setConversations(data);
                } else {
                    console.error('Failed to fetch conversations');
                }
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        }

        fetchConversations();
    }, []);

    const handleConversationClick = (conversationId) => {
        setSelectedConversationId(conversationId);
    };

    return (
        <div className='min-h-screen bg-gray-200 p-4'>
            <div className='flex'>
                <div className='w-1/4 bg-white p-4 mr-4 rounded shadow'>
                    <h1 className='text-2xl font-bold mb-4'>Conversations</h1>
                    {conversations.map((conversation) => (
                        <ConversationItem key={conversation.id} conversation={conversation} onClick={handleConversationClick} />
                    ))}
                </div>
                <div className='flex-1 bg-white p-4 rounded shadow'>
                    {selectedConversationId ? <ConversationPage conversationId={selectedConversationId} /> : <p className='text-gray-600'>Select a conversation to view messages.</p>}
                </div>
            </div>
        </div>
    );
};

export default App;
