import React from 'react';

const ConversationItem = ({ conversation, onClick }) => {
    return (
        <div className='cursor-pointer hover:bg-gray-100 p-2' onClick={() => onClick(conversation.id)}>
            Conversation {conversation.id}
        </div>
    );
};

export default ConversationItem;
