import React, { useState, useEffect } from 'react';
import MessageItem from '../components/MessageItem';

const ConversationPage = ({ conversationId }) => {
    const [conversation, setConversation] = useState({ id: '', messages: [] });

    useEffect(() => {
        async function fetchConversations() {
      try {
        const response = await fetch('/api/conversations'); // Replace with your API endpoint
        if (response.ok) {
          const data = await response.json();
          setConversation(data);
        } else {
          console.error('Failed to fetch conversations');
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    }

    fetchConversations();
    }, [conversationId]);

    return (
        <div className='p-4'>
            <h1 className='text-xl font-bold mb-4'>Conversation {conversation.id}</h1>
            {conversation.messages.map((message, index) => (
                <MessageItem key={index} message={message} />
            ))}
        </div>
    );
};

export default ConversationPage;
